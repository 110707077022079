import styled from "styled-components"
import { calculateRem } from "../../utils/javascript-styles"

const ButtonGhostInverse = styled.button`
  background: transparent;
  border-radius: ${calculateRem(2)};
  border: 0;
  box-shadow: inset 0px 0px 0px 2px ${props => props.theme.black};
  color: ${props => props.theme.black};
  padding: 1rem 1.25rem;
  transition: all 200ms;
  &:hover {
    background: ${props => props.theme.black};
    color: ${props => props.theme.white};
  }
`

export default ButtonGhostInverse
