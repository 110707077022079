import styled from "styled-components"
import { calculateRem } from "../../utils/javascript-styles"

const HrefButtonInverse = styled.a`
  background: transparent;
  border-radius: ${calculateRem(2)};
  border: 0;
  box-shadow: inset 0px 0px 0px 2px black;
  color: black;
  display: inline-block;
  margin-bottom: 1rem;
  padding: 1rem 1.25rem;
  text-decoration: none;
  transition: all 200ms;
  &:hover {
    color: white;
    background: black;
  }
`

export default HrefButtonInverse
