import React from "react"
import styled from "styled-components"
import { Row, Col } from "react-styled-flexboxgrid"
import { Primary, Secondary } from "../Section"
import { HrefButtonInverse } from "../Button"
import TouchlessFlatwareHero from "./TouchlessFlatwareHero"

import SvgImage from "../SvgImage"
import { BackgroundSectionStyle } from "../Section/backgroundSection.css.js"
import LogoTouchless from "../../images/logo-touchless.svg"
import LogoChopsticks from "../../images/logo-chopsticks.svg"

const StyledTouchlessFlatwareHero = styled(TouchlessFlatwareHero)`
  ${BackgroundSectionStyle};
  padding-top: 10rem;
  min-height: ${props => props.backgroundHeight}px;
  text-align: ${props => (props.centered ? "center" : "initial")};
`

const TouchlessFlatware = ({ sectionImg }) => {
  return (
    <section>
      <StyledTouchlessFlatwareHero backgroundHeight={550} centered>
        <h1>Touchless Flatware is the new way to dine!</h1>
      </StyledTouchlessFlatwareHero>
      <Primary>
        <Row>
          <Col xs={12} md={6}>
            <SvgImage
              width={350}
              imgSrc={LogoTouchless}
              altText="The Joyner Hand Logo"
            />
          </Col>
          <Col xs={12} md={6}>
            <h3>REVOLUTIONIZING THE WAY PEOPLE EAT, HEALTHY!</h3>
            <p>
              While dining in a restaurant, like many restaurant patrons, “Doc
              J” wanted to place his flatware on the restaurant tabletop in
              order to avoid the germs that he knew were on the table. However,
              there was no napkin, dish or place setting available to set his
              flatware. He suddenly envisioned the new unique germ resistant
              Touchless Flatware patented design. This new Touchless modern
              American design is unique in that when upright the mouthpiece end
              avoids touching the table.
            </p>
            <p>
              Touchless Flatware and the Touchless Philosophy combine royalty,
              science, and art with simplicity. Spread the word. “Go Touchless …
              Touchless is the new way to dine!”
            </p>
            <HrefButtonInverse href="https://www.touchlessflatware.com">
              Take me to Touchless Flatware
            </HrefButtonInverse>
          </Col>
        </Row>
      </Primary>
      <Secondary>
        <Row>
          <Col xs={12} md={6}>
            <SvgImage
              width={350}
              imgSrc={LogoChopsticks}
              altText="Touchless Chopsticks logo"
            />
          </Col>
          <Col xs={12} md={6}>
            <h3>TOUCHLESS CHOPSTICKS</h3>
            <p>
              TOUCHLESS CHOPSTICKS Different aspects of Chinese Style
              chopsticks, Japanese style chopsticks and Korean style chopsticks
              have all influenced the style and design of the new Touchless
              Style Chopsticks.
            </p>
            <HrefButtonInverse href="https://www.touchlessflatware.com/chopsticks">
              Take me to Touchless Chopsticks
            </HrefButtonInverse>
          </Col>
        </Row>
      </Secondary>
    </section>
  )
}

export default TouchlessFlatware
