import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { calculateRem } from "../../utils/javascript-styles"

const StyledSvgImage = styled.img`
  width: ${props => calculateRem(props.width)};
  height: ${props => calculateRem(props.height)};
`

const SvgImage = ({ imgSrc, width, altText, height }) => {
  return (
    <StyledSvgImage src={imgSrc} width={width} height={height} alt={altText} />
  )
}

SvgImage.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string.isRequired,
}

export default SvgImage
