import styled from "styled-components"
import { calculateRem } from "../../utils/javascript-styles"

const Button = styled.button`
  background: transparent;
  border-radius: ${calculateRem(3)};
  border: 0;
  box-shadow: inset 0px 0px 0px 2px ${props => props.theme.white};
  color: white;
  margin-bottom: 1rem;
  padding: 1rem 1.25rem;
  transition: all 200ms;
  &:hover {
    cursor: pointer;
    background: ${props => props.theme.white};
    box-shadow: inset 0px 0px 2px ${props => props.theme.sushi};
    color: black;
  }
`

export default Button
