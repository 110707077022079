import React from "react"
import TouchlessFlatware from "../components/TouchlessFlatware"
import { Layout } from "../components/Layout"

const TouchlessFlatwarePage = () => {
  return (
    <Layout>
      <TouchlessFlatware />
    </Layout>
  )
}

export default TouchlessFlatwarePage
