import styled from "styled-components"
import { calculateRem } from "../../utils/javascript-styles"

const HrefButton = styled.a`
  background: transparent;
  border-radius: ${calculateRem(3)};
  box-shadow: inset 0px 0px 0px 2px white;
  color: white;
  display: inline-block;
  margin-bottom: 1rem;
  padding: 1rem 1.25rem;
  transition: all 200ms;
  &:hover {
    background: white;
    color: black;
    text-decoration: none;
  }
`
export default HrefButton
