import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"
import Base from "./Base"

const defaultProps = {
  centered: false,
}

const propTypes = {
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
}
const SectionAlternate = styled(Base)`
  background-color: ${props => props.theme.black};
  text-align: ${props => (props.textCentered ? "center" : "initial")};
  color: ${props => props.theme.gallery};
  h1,
  h2,
  h3,
  h4 {
    color: ${props => props.theme.sushi};
  }
  strong {
    color: ${props => props.theme.sushi};
  }
`

const Alternate = props => {
  const { children, centered } = props
  return (
    <SectionAlternate textCentered={centered}>
      <Grid>
        <Row>
          <Col xs={12}>{children}</Col>
        </Row>
      </Grid>
    </SectionAlternate>
  )
}

Alternate.defaultProps = defaultProps
Alternate.propTypes = propTypes

export default Alternate
