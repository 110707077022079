import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"
import Base from "./Base"
import { BackgroundSectionStyle } from "./backgroundSection.css.js"

import defaultBgImg from "../../images/default-bg.jpg"

const defaultProps = {
  centered: true,
  backgroundImage: defaultBgImg,
  backgroundHeight: 500,
  backgroundPositionY: 50,
}

const propTypes = {
  children: PropTypes.node.isRequired,
  centered: PropTypes.bool,
  backgroundImage: PropTypes.node,
  backgroundHeight: PropTypes.number,
  backgroundPositionY: PropTypes.number,
}
const SectionParallax = styled(Base)`
  ${BackgroundSectionStyle};
  background: url(${props => props.bgImage}) no-repeat center center;
  background-size: cover;
  background-position-y: ${props => props.bgPosY}%;

  text-align: ${props => (props.textCentered ? "center" : "initial")};
  min-height: ${props => props.bgHeight}px;
  color: white;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${props => props.theme.haiti};
  }

  strong {
    color: ${props => props.theme.oil};
  }
`

const Parallax = props => {
  const {
    children,
    centered,
    backgroundImage,
    backgroundHeight,
    backgroundPositionY,
  } = props
  return (
    <SectionParallax
      textCentered={centered}
      bgImage={backgroundImage}
      bgHeight={backgroundHeight}
      bgPosY={backgroundPositionY}
    >
      <Grid>
        <Row middle="xs">
          <Col xs={12}>{children}</Col>
        </Row>
      </Grid>
    </SectionParallax>
  )
}

Parallax.defaultProps = defaultProps
Parallax.propTypes = propTypes

export default Parallax
