import { css } from "styled-components"
import { calculateRem } from "../../utils/javascript-styles"

export const BackgroundSectionStyle = css`
  background-size: cover;
  position: relative;
  z-index: 100;
  background-repeat: no-repeat;

  color: white;
  padding-top: ${calculateRem(125)};
  padding-right: ${calculateRem(16)};
  padding-bottom: ${calculateRem(125)};
  padding-left: ${calculateRem(16)};

  h1 {
    font-size: ${calculateRem(68)};
    font-weight: 700;
    text-shadow: 1px 1px 0 ${props => props.theme.black};
  }
  h2 {
    font-size: ${calculateRem(48)};
    font-weight: 700;
    text-shadow: 1px 1px 0 ${props => props.theme.black};
  }
  h3 {
    font-size: ${calculateRem(38)};
    text-shadow: 1px 1px 0 ${props => props.theme.black};
  }
  h4 {
    font-size: ${calculateRem(22)};
  }
  img {
    z-index: 100;
  }
  blockquote {
    font-size: ${calculateRem(38)};
    text-shadow: 1px 1px 0 ${props => props.theme.black};
    font-style: italic;
  }
  /* Small only */
  @media screen and (max-width: 39.9375em) {
    h1 {
      font-size: ${calculateRem(48)};
    }
    h2 {
      font-size: ${calculateRem(38)};
    }
    h3 {
      font-size: ${calculateRem(28)};
    }
    h4 {
      font-size: ${calculateRem(18)};
    }
    blockquote {
      font-size: ${calculateRem(28)};
    }
  }
`
