import styled from "styled-components"
import { calculateRem } from "../../utils/javascript-styles"

const Button = styled.button`
  background: ${props => props.theme.sushi};
  color: white;
  padding: 1rem 1.25rem;
  border-radius: ${calculateRem(3)};
  border: 0;
  box-shadow: inset 0px 0px 0px 2px ${props => props.sushi};
  transition: background 300ms;
  margin-bottom: 1rem;
  &:hover {
    cursor: pointer;
    background: ${props => props.sushi};
    box-shadow: inseet 0px 0px 2px ${props => props.sushi};
  }
`

export default Button
